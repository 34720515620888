function valwocolon(strval)
{
    var strtosplit ="";
    if(strval.indexOf(':') == -1)
    {
        strtosplit = strval;
    }
    else
    {
        var x = strval.split(":");
        for(var i=0;i<x.length;i++)
        {
            if(x[i]!='')
            {
                strtosplit +=x[i];
            }
        }
    }
    return strtosplit;
}

function autoTabTimes(input)
{
    var len = input.length;
    if (len<3) {
        input = "";
        return input;
    }
    if (len==3)
    {
        input ="0" + input;
    }
    //var final = input.value.split("");
    var strtosplit = valwocolon(input);
    var final = strtosplit.split("");
    var h = Number(final[0] + final[1]);
    var m = Number(final[2] + final[3]);

    var f = final[0]+final[1]+":"+final[2]+final[3];
    input = f;

    return input
}

function strip(which)
{
    var x = which.value;
    x = x.replace(/[^0-9]/g,""); // allow only digits
    which.value = x;
}


function autoTabTimesHtml(input) {
    var len = input.value.length;
    if (len<2) {
        input.value = "";
        return false;
    }
    if (len==2){
        input.value ="00" + input.value;
    }
    if (len==3){
        input.value ="0" + input.value;
    }
//var final = input.value.split("");
    strtosplit = valwocolon(input.value);
    var final = strtosplit.split("");
    var h = Number(final[0] + final[1]);
    var m = Number(final[2] + final[3]);
    if (h <0 || h >23 || m <0 || m >59) {
        input.value = "";
        return false;
    }
    var f = final[0]+final[1]+":"+final[2]+final[3];
    input.value = f;
}

function convertStringToFormattedTime(strval, mydecimal)
{
    // Default mydecimal to 0
    var isdecimal = mydecimal || 0;
    // Is it a decimal conversion?
    if(isdecimal) return convertStringToDecimalMinutes(strval)
    // Is it a normal conversion
    return autoTabTimes(strval)
}

function convertStringToDecimalMinutes(strval)
{
    // Input can be with or without the decimal point 12, or 1.2
    // return 1.2 and 1.2 in both cases
    if((strval == '0') || (strval == '')) return '0.0'

    // Check if a decimal is present
    if(strval.indexOf('.') > 0) return strval

    var position = strval.length -1
    // If not, we'll stick one in ..
    var output = strval.substr(0, position) + '.' + strval.substr(position);

    return output
}


